<script>
import StatisticCardHorizontal from '@core/components/statistics-cards/StatisticCardHorizontal.vue'

export default {
  name: 'StatisticCardHorizontal',
  extends: StatisticCardHorizontal,
  props: {
    statistic: {
      type: null,
      required: true,
    },
  },
}

</script>
